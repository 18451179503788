/**
 * @file scroll-hint
 * https://github.com/appleple/scroll-hint
 */

import ScrollHint from 'scroll-hint';

(function () {
  new ScrollHint('[data-js-scrollable]', {
    i18n: {
      scrollable: '横にスクロールできます',
    },
  });
})();
