/**
 * @file matchHeight
 * https://github.com/liabru/jquery-match-height
 */

import '../lib/_jquery.matchHeight-min';
const $window = $(window);

// ----------------------------------------------------------------
// [data-js-matchHeight]
// ----------------------------------------------------------------
$window.on('load resize', function () {
  const $root = $('[data-js-match-height="root"]');
  $.each($root, (_, target) => {
    $('[data-js-match-height="child"]', $(target)).matchHeight();
  });
});
