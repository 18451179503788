/**
 * @file グローバル変数を定義
 *
 */

// ユーザーエージェント
export const ua = navigator.userAgent;
export const isIE = ua.toLowerCase().indexOf('msie') !== -1 || ua.toLowerCase().indexOf('trident') !== -1 || ua.toLowerCase().indexOf('edge') !== -1;

// URLパラメーター
// @use: /blueplanet/list.html?tabid=2012
//       urlParameter.tabid: "2012"
export const urlParameter = (function () {
  const url = location.search.substring(1).split('&');
  var param = [];
  for (var i = 0; url[i]; i++) {
    var k = url[i].split('=');
    param[k[0]] = k[1];
  }
  return param;
})();

// コンテンツサイズ
export const contentsSize = 1000;

// ブレイクポイント
export const breakpointMd = 768;
export const breakpointLg = 1024;
export const breakpointXlg = 1200;

// デバイス判定
export const mqSm = window.matchMedia('(max-width:' + (breakpointMd - 1) + 'px)');
export const mqMd = window.matchMedia('(max-width: ' + (breakpointXlg - 1) + 'px) and (min-width: ' + breakpointMd + 'px)');
export const mqLg = window.matchMedia('(min-width:' + breakpointXlg + 'px)');
// export const isMq = mqMd.matches ? 'pc' : 'sp';
// export const isMqMd = isMq === 'pc' ? true : false;
// export const isMqMdDown = isMq === 'sp' ? true : false;
export const isMqSm = mqSm.matches;
export const isMqMd = mqMd.matches;
export const isMqLg = mqLg.matches;
export const isMq = () => {
  if (mqLg.matches) {
    return 'lg';
  } else if (mqMd.matches) {
    return 'md';
  } else {
    return 'sm';
  }
};

// 限定デバイス判定
//
// 使い方 example
// mq.Pc.addListener( function(){} ); // ブレークポイントを経過した際に実行
// if( mq.Pc.matches ){ function(){} }; // mq.Pcに一致するwindowサイズの際に実行
//
// | ~1200 | ~1024 | ~768 | ~0   |
// |       Pc      |             |
// |       | Pcmd  |             |
// |               |  Tb  |      |
// |                      |  Sp  |
//
export const mq = {
  Pc: window.matchMedia('(min-width: ' + breakpointLg + 'px)'),
  PcTb: window.matchMedia('(min-width: ' + breakpointMd + 'px)'),
  Pcmd: window.matchMedia('(max-width: ' + (breakpointXlg - 1) + 'px) and (min-width: ' + breakpointLg + 'px)'),
  PcmdTb: window.matchMedia('(max-width: ' + (breakpointXlg - 1) + 'px) and (min-width: ' + breakpointMd + 'px)'),
  Tb: window.matchMedia('(max-width: ' + (breakpointLg - 1) + 'px) and (min-width: ' + breakpointMd + 'px)'),
  TbSp: window.matchMedia('(max-width: ' + (breakpointXlg - 1) + 'px)'),
  Sp: window.matchMedia('(max-width: ' + (breakpointMd - 1) + 'px)'),
};

// state modifier クラス
export const activeClass = 'is-active';
export const changeClass = 'is-changing';
export const currentClass = 'is-current';
export const disabledClass = 'is-disabled';
