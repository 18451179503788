/**
 * @file object-fitのpolyfill
 * https://github.com/constancecchen/object-fit-polyfill
 */

import 'objectFitPolyfill';
import { isIE } from '../data/_variables';

const $window = $(window);

$window.on('load resize', function () {
  if (isIE) window.objectFitPolyfill();
});
