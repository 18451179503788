/**
 * @file ハンバーガーメニュー
 */

// ----------------------------------------------------------------
// [data-js-menu]
// ----------------------------------------------------------------

import { activeClass, mq } from '../data/_variables';

(function () {
  const $menu = $('[data-js-menu]');
  const $menuClose = $('[data-js-menuClose]');
  const $menuContents = $('[data-js-menuContents]');
  const fadeSpeed = 200;
  let timer = 0;

  // 開く時
  const open = () => {
    $menu.addClass(activeClass);
    $menuClose.addClass(activeClass);
    $menuContents.fadeIn(fadeSpeed).addClass(activeClass);
  };

  // 閉じる時
  const close = () => {
    $menu.removeClass(activeClass);
    $menuClose.removeClass(activeClass);
    $menuContents.fadeOut(0).removeClass(activeClass);
  };

  const init = () => {
    // メニューアイコンを押した時
    $menu.on('click', () => {
      if ($menu.hasClass(activeClass)) {
        close();
      } else {
        open();
      }
    });

    // 閉じるアイコンを押した時
    $menuClose.on('click', () => {
      close();
    });

    // リサイズ時
    $(window).on('resize', () => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        if (!mq.TbSp.matches) {
          close();
          $menuContents.attr('style', '');
        }
      }, 0);
    });
  };

  init();
})();
