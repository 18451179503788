/**
 * @file スクロールしたらclass付与
 *
 */
const $window = $(window);
const $pagetop = $('#js-pagetop');
const activeClass = 'is-show';

$pagetop.on('click', (event) => {
  // デフォルトの挙動を無効化
  event.preventDefault();

  const $this = $(event.currentTarget);

  // 移動先IDを取得
  const href = $this.attr('href');

  // 移動先を取得
  const $target = $(href === '#' || href === '' ? 'html' : href);

  const position = $target.offset().top;
  $('body,html').animate({ scrollTop: position }, 400, 'swing');
});

$window.on('load scroll', () => {
  if ($window.scrollTop() > 100) {
    // スクロール量が100を超えたらclass付与
    $pagetop.addClass(activeClass);
  } else {
    // スクロール量が100を超えていなかったらclass除去
    $pagetop.removeClass(activeClass);
  }
});
