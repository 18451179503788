/**
 * @file スライダー
 * http://kenwheeler.github.io/slick/
 */

import 'slick-carousel';

// ----------------------------------------------------------------
// [data-js-slider]
// ----------------------------------------------------------------
(function () {
  const $slider = $('[data-js-slider]');
  $slider.on('init', function (event, slick, direction) {});
  $slider.on('afterChange', function (event, slick, currentSlide) {});
  $.each($slider, (_, target) => {
    $(target).slick({
      infinite: true,
      arrows: $(target).data('jsSliderArrow') !== undefined,
      // arrows: false,
      centerMode: true,
      centerPadding: '0px',
      variableWidth: false,
      adaptiveHeight: true,
      swipeToSlide: true,
      dots: true,
      dotsClass: 'slide-dots',
      autoplay: true,
      autoplaySpeed: 3000,
      speed: 1000,
      // lazyLoad: 'progressive',
    });
  });
})();
