/**
 * @file 台形グラデーション表現
 */

import { isMq } from '../data/_variables';
const $window = $(window);

const createCanvas = () => {
  const targets = document.querySelectorAll('[data-js-trapezoid]');
  Array.prototype.forEach.call(targets, (target) => {
    const canvas = document.createElement('canvas');
    target.appendChild(canvas);
  });
};
const createTrapezoid = (target, width, height, length, color = false) => {
  const canvas = target.querySelector('canvas');
  canvas.width = width;
  canvas.height = height;
  const ctx = canvas.getContext('2d');
  ctx.beginPath();
  ctx.moveTo(length, 0);
  ctx.lineTo(width, 0);
  ctx.lineTo(width, height);
  ctx.lineTo(0, height);
  ctx.lineTo(length, 0);
  ctx.fill();

  const grad = ctx.createLinearGradient(0, 0, width, 0);
  if (!color) {
    grad.addColorStop(0, '#009de0');
    grad.addColorStop(1, '#009ea0');
  } else {
    grad.addColorStop(0, color);
    grad.addColorStop(1, color);
  }

  ctx.fillStyle = grad;
  ctx.fill();
};

const transformTrapezoid = () => {
  const targets = document.querySelectorAll('[data-js-trapezoid]');
  Array.prototype.forEach.call(targets, (target) => {
    const width = target.clientWidth;
    const height = target.clientHeight;
    const length = (() => {
      const length_set = [];
      const data = target.dataset.jsTrapezoid.trim().split(',');
      length_set.push(data[0]);
      if (data.length <= 1) {
        length_set.push(data[0]);
        length_set.push(data[0]);
      } else if (data.length == 2) {
        length_set.push(data[0]);
        length_set.push(data[1]);
      } else {
        length_set.push(data[1]);
        length_set.push(data[2]);
      }
      return isMq() === 'lg' ? length_set[0] : isMq() === 'md' ? length_set[1] : length_set[2];
    })();
    const color = target.dataset.jsTrapezoidColor;
    createTrapezoid(target, width, height, length, color);
  });
};

$window.on('load', function () {
  createCanvas();
  transformTrapezoid();
});

$window.on('resize', function () {
  transformTrapezoid();
});
