/**
 * @file ScrollReveal
 * https://github.com/jlmakes/scrollreveal
 */

import ScrollReveal from 'scrollreveal';

// フェードイン
ScrollReveal().reveal('[data-js-floating="1"]', {
  delay: 1000 * 0,
  duration: 1000,
  origin: 'bottom',
  distance: '0',
});

// 右から左
ScrollReveal().reveal('[data-js-floating="2"]', {
  delay: 1000 * 1,
  duration: 1000,
  origin: 'right',
  distance: '50px',
});

ScrollReveal().reveal('[data-js-floating="3"]', {
  delay: 1000 * 2,
  duration: 1000,
  origin: 'right',
  distance: '50px',
});
