/**
 * @file lazyload
 * https://github.com/aFarkas/lazysizes
 */

import 'lazysizes';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';

window.lazySizesConfig = window.lazySizesConfig || {};
window.lazySizesConfig.customMedia = {
  '--small': '(max-width: 767px)',
  '--medium': '(min-width: 768px) and (max-width: 1199px)',
  '--large': '(max-width: 1199px)',
};
